<template>
	<div class="mt-3">

		<div v-if="isReady && printers.length">
			<b-form>
				<div class="row">
					<div class="col-md-8">
						<b-form-group
							id="input-group-1"
							label="FM AWB"
							label-size="sm"
						>
							<b-form-input
								v-model="fm_awb"
								@keyup.enter="fmAdded"
								type="text"
								ref="fm_awb"
								:autofocus="fm_awb?false:true"
								placeholder="FM AWB"
								required
								@input="(val) => (fm_awb = fm_awb.toUpperCase())"
							></b-form-input>
								<!-- :readonly="fmDisable" -->

							<b-form-invalid-feedback :state="validation">
								Please enter FM AWB field.
							</b-form-invalid-feedback>
							<small v-if="total" class="text-success">
								Total LMs: {{total}}
							</small>

							<small v-if="bags.length > 0" :class="total==bags.length ? 'text-success' : 'text-danger'">
								/ Scanned: {{bags.length}}
							</small>
							<small v-if="fm_dims">
								({{fm_dims}})
							</small>
						</b-form-group>
					</div>
				</div>

				<div class="row">
					<div class="col-md-8">
						<b-form-group
							id="input-group-1"
							label="Shypmax ID"
							label-size="sm"
						>
							<b-form-input
								v-model="item.lm_awb"
								type="text"
								ref="lm_awb"
								:autofocus="fm_awb?true:false"
								placeholder="Shypmax ID"
								@keyup.enter="lmAdded"
								size="sm"
								required
							></b-form-input>
						</b-form-group>
					</div>
				</div>

				<div v-if="bags && bags.length" class="row">
					<div class="col-sm-12 mt-4">
						<h4>Added Packages</h4>
						<b-table 
							striped 
							hover 
							:items="bags" 
							:fields="fields" 
							:tbody-tr-class="rowClass"
						>

							<template v-slot:cell(lm_awb)="data">
								<div class="d-flex">
									<span>
										{{data.item.lm_awb}}
									</span>

									<span 
										@click="printLabel(data.item.lm_awb)" 
										class="btn-sm btn-primary float-right pointer ml-2" 
										title="Print Label" 
										style="margin-top: -5px;"
										v-if="data.item.printlmlabel"
										v-b-tooltip.hover
									>
										<b-icon-printer></b-icon-printer>
									</span>
								</div>
							</template>

							<template v-slot:cell(h_awb)="data">
								<div class="d-flex">
									<span>
										{{data.item.h_awb}}
									</span>

									<span 
										@click="printBarcode(data.item)" 
										class="btn-sm btn-primary float-right pointer ml-2" 
										title="Print Barcode" 
										style="margin-top: -5px;"
										v-b-tooltip.hover
									>
										<b-icon-printer></b-icon-printer>
									</span>
								</div>
							</template>

							<template v-slot:cell(created)="data">
								<div class="d-flex">
									<span>
										{{data.item.created | date }}
									</span>
								</div>
							</template>

						</b-table>
					</div>
				</div>

			</b-form>

			<!-- Print Bagcode -->
			<!-- <div style="display:none"> -->
				<span id="capture">
					<VueBarcode :value="print_data.h_awb" :text="print_data.country_hawb" :fontSize="15" height="70" width="2">
						Failed to generate barcode.
					</VueBarcode>
				</span>
			<!-- </div> -->
 
			<div v-if="label_url">
				<!-- Print Label -->
				<div id="capture-label">
					<div v-if="filetype == 'dpdLabel'">
						<a :href="label_url" :download="filename">Download Label</a> 
					</div>

					<div v-else-if="filetype == 'pdf'">
						<a :href="label_url" :download="filename">Download Label</a> 
					</div>

					<img v-else :src="label_url" alt="label">
				</div>
			</div>

		</div>

		<div v-else>
			<AssignPrinter :printers="printers" v-if="printers" />
		</div>

	</div>
</template>

<script>

import VueBarcode from 'vue-barcode';
import * as JSPM from 'jsprintmanager'
import html2canvas from 'html2canvas'
import AssignPrinter from '@/components/AssignPrinter.vue'

export default {
	name: 'InScan',
	title: 'InScan',
	components: {
		VueBarcode,
		AssignPrinter
	},
	data() {
		return {
			isBusy: false,
			item: {},
			total: null,
			fm_dims: this.$store.getters.global.fm_dims || '',
			length: '',
			bags: [],
			print_data: {},
			fm_awb: this.$store.getters.global.fm_awb || '',
			fmDisable: false,
			printers: [],
			validation: undefined,
			isReady: false,
			filetype: ``,
			filename: ``,
			label_url: '',
			dpdLabel_url: '',
			hawb: ``,
			fields: [
				{
                    key: 'fm_awb', 
                    label: 'FM AWB'
                },
				{
                    key: 'h_awb', 
                    label: 'Shypmax ID'
                },
				{
                    key: 'lm_awb', 
                    label: 'LM AWB'
                },
				{
                    key: 'created', 
                    label: 'Inscan Date'
                }
			]
		}
	},

	created() {

		if(this.fm_awb) {
			this.fmDisable = true
			this.getBags()
		}

	},


	mounted() {

		this.onInit();

		if((this.label_printer || this.barcode_printer)) {
			this.isReady = true
		}
		
	},


	methods: {

		rowClass(item, type) {

			if (item && type === 'row') {

				if ( item.rowColor ) {
					return 'row-danger'
				}
                else {
					return "row-normal"
				}

			}
            else {
				return "row-normal"
			}

		},


        async fmAdded() {

			try {

				this.fm_awb = this.fm_awb.toUpperCase().trim();

				this.$store.commit('setGlobal', {fm_awb: this.fm_awb})

				const postData = { fm_awb: this.fm_awb };

				const res = await this.axios.post(`/saveFmAwb_nido`, postData);

				if(res) {
					this.getBags()
					this.$refs.lm_awb.focus();
					// this.fmDisable = true;
					this.isBusy = false;
				}
				else {
					this.popToast('Failed!', 'Unable to save FM-AWB dimension.', 'danger')
					this.fm_awb = ''
					this.isBusy = false
				}

			}
			catch (err) {
				console.error(err)
			}
                
        },


		async getBags(lmawb = ``) {

			if(this.fm_awb) {

				this.isBusy = true;
				const res = await this.axios.get(`/inscan/${this.fm_awb}`);

				if(res.data.success) {

					this.total = res.data.count;
					
					if(lmawb) {
						let filteredData = res.data.data.filter(elt => elt.h_awb == lmawb);
						let notFilteredData = res.data.data.filter(elt => elt.h_awb != lmawb);

						this.bags = [...filteredData, ...notFilteredData];
					}
					else {
						this.bags = res.data.data;
					}

				}

				this.isBusy = false;
			}

		},


		async lmAdded() {

			if(!this.item.lm_awb) {
				return 
			}

			try {

				setTimeout(async () => {

					this.isBusy = true;
					this.validation = undefined;

					this.onSubmit();

				}, 1000);

			}
            catch (err) {
				console.log(err)
			}

		},

		async onSubmit() {

			let form = this.item;
			form.fm_awb = this.fm_awb;
            
			try {

				this.isBusy = true;

                const res = await this.axios.post('/inscan_nido', form);

                if(res.data.success) {

					if(res.data.h_awb) {
						
						form["h_awb"] = res.data.h_awb;
						
						form["country_hawb"] = `${res.data.country}-${res.data.h_awb}`
						
						this.item["h_awb"] = res.data.h_awb
						this.item["rowColor"] = res.data.row_status
						this.bags.unshift(this.item)
						
						this.printBarcode(form);
						
					}

					this.item = {}
					this.length = ''
					this.$refs.lm_awb.focus();

					// Get table data
					this.getBags();

					this.filename = res.data.lm_awb;

					// If label exists then print it by JSPM 
					let booking = res.data.bookingResult;

					if(booking && Object.keys(booking).length) {
						
						this.label_url = booking && booking.base64Image ? booking.base64Image : booking.labelPath;
						
						if(booking.dpdLabelPath) {
							this.filetype = 'dpdLabel'
						}
						else if(booking.labelPath.includes('application/pdf')) {
							this.filetype = 'pdf'
						}
						else if(booking.base64Image || booking.labelPath.includes('application/png')) {
							this.filetype = 'base64Image';
						}
						// else {
						// 	this.filetype = 'png'
						// }
						
						setTimeout(() => {
							this.printLabelImage(booking.dpdLabelPath?.length ? booking.dpdLabelPath : booking.labelPath, this.filename)
						});
						
					}

                } 
                else {
                    // this.item = {}
                    // this.length = ''
                    this.popToast('Failed!', res.data.message, 'danger')
					this.getBags(this.item.lm_awb);
                }

				this.isBusy = false;

			}
            catch (err) {
				this.isBusy = false
				console.log(err)
			}

			this.$refs.lm_awb.focus();
			this.isBusy = false;

		},


		async printBarcode(bag) {

			this.print_data = bag;
			
			setTimeout(() => {
				const contents = document.getElementById('capture').innerHTML;
				const w=window.open('','','left=0,bottom=0,width=100,height=1,toolbar=0,scrollbars=0,status=0');
				w.document.write(contents);
				w.document.close();
				w.focus();
				w.print();
				w.close();
			}, 500);



			// try {
			// 	// Check if jsPrintManager is supported
			// 	if (JSPM.JSPrintManager.auto_reconnect) {
					
			// 		// Get available printers
			// 		let printers = await JSPM.JSPrintManager.getPrinters();
					
			// 		// For simplicity, let's select the default printer or the first printer
			// 		let myPrinter = printers.find(p => p.defaultPrinter) || printers[0];

			// 		// Prepare the content to print
			// 		let content = document.getElementById('capture').outerHTML;  // Assuming you have an area with the ID 'printableArea' that you want to print

			// 		// Create a print job
			// 		let myDoc = new JSPM.PrintDocument({
			// 			documentContent: content,
			// 			printer: myPrinter,
			// 			docName: 'MyDocument.html'
			// 		});

			// 		// Print
			// 		JSPM.JSPrintManager.print(myDoc);
					
			// 	} else {
			// 		console.error('JSPrintManager plugin is not installed or not supported in this browser.');
			// 	}
			// } catch (error) {
			// 	console.error('Printing error:', error);
			// }

			
		},


		onInit: function () {
			var _this = this;
			JSPM.JSPrintManager.license_url = `${process.env.VUE_APP_API_ENDPOINT}/wh-license`;
			JSPM.JSPrintManager.auto_reconnect = true;
			JSPM.JSPrintManager.start();
			JSPM.JSPrintManager.WS.onStatusChanged = function () {
				_this.getPrinters().then((p) => {
					_this.printers = p;
					_this.$nextTick();
				});
			};
		},

		jspmWSStatus() {
			if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Open) {
				return true;
			}else if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Closed) {
				alert('JSPrintManager (JSPM) is not installed or not running in the print server! Download and install JSPM Client App from https://neodynamic.com/downloads/jspm');
				this.isReady = false
				return false;
			}
			else if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Blocked) {
				alert('JSPM has blocked this website!');
				return false;
			}
		},

		async printLabel(lm_awb) {

			try {

				const res = await this.axios.post(`/lmLabel`, {lmAwb: lm_awb})

				if(res.data.success) {

					this.dpdLabel_url = res.data?.dpdLabelPath;
					this.label_url = res.data?.image
					this.filename = res.data?.name
					this.hawb = res.data?.hawb


					// if(this.hawb) {
					// 	setTimeout(() => {
					// 		this.printBarcode(res.data.hawb)
					// 	});
					// }

					// Print DPD label
					if(this.dpdLabel_url) {
						this.filetype = 'dpdLabel'
					}
					else if(this.filename.includes('.pdf')) {
						this.filetype = 'pdf'
					}
					else {
						this.filetype = 'png'
					}	

					setTimeout(() => {
						this.printLabelImage(this.dpdLabel_url?.length ? this.dpdLabel_url : this.label_url, this.filename)
					});
					
				}
				else {
					this.popToast('Failed!', res.data.message, 'danger')
				}

			}
			catch (err) {
				this.lm_awb = ''
				console.error(err)
			}

		},


		async printLabelImage(url, fileName) {

			if(this.jspmWSStatus()) {

				if(this.filetype == 'dpdLabel') {

					// Printing label for the dpd file type
					if(this.$store.getters.global.label_printer) {

						let cpj = new JSPM.ClientPrintJob();
						cpj.clientPrinter = new JSPM.InstalledPrinter(this.$store.getters.global.label_printer);

						cpj.printerCommands = url;

						//Send print job to printer!
						cpj.sendToClient();

					}

				}
				else if(this.filetype == 'pdf') {

					// printing label for pdf file type only
					if(this.$store.getters.global.label_printer) {
						let cpj = new JSPM.ClientPrintJob();
						cpj.clientPrinter = new JSPM.InstalledPrinter(this.$store.getters.global.label_printer);

						//Set content to print... in this sample, a pdf file
						const myPdfFile = new JSPM.PrintFilePDF(url, JSPM.FileSourceType.URL, fileName, 1);

						//add file to print job
						cpj.files.push(myPdfFile);

						//Send print job to printer!
						cpj.sendToClient();
					}
					
				}
				else {

					// printing label for base64 image and a png file type only
					html2canvas(document.querySelector("#capture-label")).then(canvas => {
						// document.body.appendChild(canvas)
						if(this.$store.getters.global.label_printer) {
							let cpj = new JSPM.ClientPrintJob();
							cpj.clientPrinter = new JSPM.InstalledPrinter(this.$store.getters.global.label_printer);

							//Set content to print... 
							const b64Prefix = "data:image/png;base64,";
							const imgBase64DataUri = canvas.toDataURL("image/png");
							const imgBase64Content = imgBase64DataUri.substring(b64Prefix.length, imgBase64DataUri.length);
							const myImageFile = new JSPM.PrintFile(imgBase64Content, JSPM.FileSourceType.Base64, 'labelToPrint.png', 1);
							
							//add file to print job
							cpj.files.push(myImageFile);
							cpj.sendToClient();
						}
					});

				}
			}
		},
		

		getPrinters: function () {
			return new Promise((ok, err) => {
				let printers = [null];
				if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Open) {
					this.errormsg = ''
					JSPM.JSPrintManager.getPrinters()
						.then(function (myPrinters) {
							printers.push(...myPrinters);
							ok(printers);
						})
						.catch((e) => err(e));
				} else {
					console.warn("JSPM WS not open");
					this.errormsg = 'Please start JSPrintManager application.'
					ok(printers);
				}
			});
		},

	},

	computed: {
		label_printer() {
			return this.$store.getters.global.label_printer || null
		},
		barcode_printer() {
			return this.$store.getters.global.barcode_printer || null
		},
	}

}
</script>

<style scoped>
	tbody tr:first-child input {
		margin-bottom: 8px;
	}

	img, .pdf {
		width: 100%;
	}
	
	.img {
		width: 100%;
		height: 450px;
		overflow: auto;
	}

</style>
